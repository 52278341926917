import React from "react"
import { Link, graphql } from "gatsby"
import { isMobile } from "react-device-detect"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import YouTubeMIcon from '@material-ui/icons/YouTube'
import TwitterMIcon from '@material-ui/icons/Twitter'
import InstagramMIcon from '@material-ui/icons/Instagram'
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
    },
    margin: "0 auto",
    width: "100%"
  },
  title: {
    paddingTop: theme.spacing(5),
    fontSize: `3rem`,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    marginBottom: '0'
  },
  body: {
    fontSize: '1rem',
    color: "#424242",
    paddingBottom: theme.spacing(5),
  },
  share: {
    paddingBottom: theme.spacing(5),
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  list: {
    backgroundColor: theme.palette.error.main
  },
  link: {
    color: "#424242",
    boxShadow: "none"
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    display: 'none',
  },
}))

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteSummary = data.site.siteMetadata.summary
  const url = data.site.siteMetadata.siteUrl
  const author = data.site.siteMetadata.author
  const social = data.site.siteMetadata.social
  const { previous, next, slug } = pageContext

  const classes = useStyles()

  // popover
  const [proverEl, setProverEl] = React.useState(null)
  const [proverText, setProverText] = React.useState(null)

  const handlePopoverOpen = (event, text) => {
    setProverEl(event.currentTarget);
    setProverText(text)
  }

  const handlePopoverClose = () => {
    setProverEl(null);
  }

  const popoverOpen = Boolean(proverEl)
  // /popover

  // menu
  const [menuEl, setMenuEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setMenuEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }
  // /menu
  const share = (
              <div className={classes.share}>
                <TwitterShareButton onMouseEnter={isMobile ? () => (false) : (e) => handlePopoverOpen(e, "Twitterでシェア")} onMouseLeave={handlePopoverClose} className={classes.buttons} title={`${post.frontmatter.title} | ${siteTitle}\n`} via={social.twitter} url={url + slug}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <FacebookShareButton onMouseEnter={isMobile ? () => (false) : (e) => handlePopoverOpen(e, "Facebookでシェア")} onMouseLeave={handlePopoverClose} className={classes.buttons} url={url + slug} quote={`${post.frontmatter.title} | ${siteTitle}\n`}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LinkedinShareButton onMouseEnter={isMobile ? () => (false) : (e) => handlePopoverOpen(e, "Linkedinでシェア")} onMouseLeave={handlePopoverClose} title={`${post.frontmatter.title} | ${siteTitle}\n`} className={classes.buttons} url={url + slug}>
                  <LinkedinIcon  size={32} round />
                </LinkedinShareButton>
                <Popover
                  id="mouse-over-popover"
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                  open={popoverOpen}
                  anchorEl={proverEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography>{proverText}</Typography>
                </Popover>
              </div>
  )
  return (
    <Layout location={location} title={siteTitle} style={{textAlign: "center"}}>
      <SEO
        title={`${post.frontmatter.title} ${siteSummary}`}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.cover.childImageSharp.fluid.src}
      />
        <article className={classes.root}>

          <Card>
            <CardHeader
              title={<span role="img" aria-labelledby="img">🥩 {post.frontmatter.title}</span>}
              subheader={post.frontmatter.date}
            />

            <CardMedia
              className={classes.media}
              image={post.frontmatter.cover.childImageSharp.fluid.src}
              title={post.frontmatter.title}
            />
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar} src={post.frontmatter.avatar.childImageSharp.fixed.src} />
              }
              action={
                <IconButton aria-label="settings" aria-controls="sns-menu" aria-haspopup="true" onClick={handleMenuClick}>
                  <StarIcon />
                </IconButton>
              }
              title={"Written by " + author.name}
              subheader={author.summary}
            />
            <CardContent>
              {share}
              <Typography variant="h4" component="h1" className={classes.title}>
                <span role="img" aria-labelledby="img">🥩 {post.frontmatter.title}</span>
              </Typography>

              <Typography variant="body2" component="div" className={classes.body}
                dangerouslySetInnerHTML={{ __html: post.html }} />

              <Typography variant="body1" component="p" className={classes.body} >
                役にたったら⬇のアイコンからシェアお願いします<span role="img" aria-labelledby="img">🙏</span>
                コメントはTwitterやYoutubeで待ってます♪
              </Typography>
              {share}
              <hr />
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.root}
                spacing={4}
              >
                <Grid item xs={4} sm={4} key="prev">
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev" className={classes.link}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.list}>
                            <ArrowBackIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={previous.frontmatter.title} className={classes.label}/>
                      </ListItem>
                    </Link>
                  )}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Link to="/" rel="home" className={classes.link}>
                    <ListItem style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "40px",
                      paddingLeft: 0}}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.list}>
                          <HomeIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item xs={4} sm={4} key="next" aligin="right">
                  {next && (
                    <Link to={next.fields.slug} rel="prev" className={classes.link}>
                      <ListItem style={{paddingRight: "0"}}>
                        <ListItemText style={{textAlign: "right", paddingRight: "16px"}} primary={next.frontmatter.title} className={classes.label}/>
                        <ListItemAvatar>
                          <Avatar className={classes.list}>
                            <ArrowForwardIcon />
                          </Avatar>
                        </ListItemAvatar>
                      </ListItem>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Popover
            id="sns-menu"
            anchorEl={menuEl}
            keepMounted
            open={Boolean(menuEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableRestoreFocus
          >
            <a className={classes.link} href={`https://twitter.com/${social.twitter}`} rel="noopener noreferrer" target="_blank">
              <MenuItem>
                <ListItemAvatar>
                  <Avatar>
                    <TwitterMIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Twitter" secondary={`@${social.twitter}`}/>
              </MenuItem>
            </a>
            <a className={classes.link} href={`https://www.instagram.com/${social.instagram}`} rel="noopener noreferrer" target="_blank">
            <MenuItem>
              <ListItemAvatar>
                <Avatar>
                  <InstagramMIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Instagram" secondary={`@${social.instagram}`}/>
            </MenuItem>
            </a>
            <a className={classes.link} href={`https://www.youtube.com/channel/${social.youtube}`} rel="noopener noreferrer" target="_blank">
            <MenuItem>
              <ListItemAvatar>
                <Avatar>
                  <YouTubeMIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Youtube" secondary={`${siteTitle} チャンネル`}/>
            </MenuItem>
            </a>
          </Popover>
        </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        summary
        author {
          name
          summary
        }
        social {
          twitter
          instagram
          youtube
        }
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        avatar {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cover {
          childImageSharp {
            fluid(maxHeight: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
